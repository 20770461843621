.dashboard {
    margin: 80px 40px;
    box-sizing: border-box;
    height: auto;
    width: auto;
    padding: 30px 45px;
    border-radius: 12px;
    background: #00111c;
    box-shadow: -6px 6px 12px #000a11,
        6px -6px 12px #001827;
}

.profile--title {
    box-sizing: border-box;
    color: #393646;
    display:inline-block;
    padding: 20px;
    box-shadow:  -5px 5px 10px #cecece;
    background: #F4EEE0;
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
    border-radius: 25px;

}

.profile--info {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* align-items: flex-start; */

}

.riderequests {
    display: flex;
    /* height: 100%; */
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;

}


.card {
    width: 250px;
    height: auto;
    background: #002137;
    border-radius: 15px;
    box-shadow: 1px 5px 60px 0px #100a886b;
    padding: 0 20px;
    padding-bottom: 20px;
}

.card .card-border-top {
    width: 60%;
    height: 3%;
    background: #6b64f3;
    margin: auto;
    border-radius: 0px 0px 15px 15px;
}
.card .profile--buttons {

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
}

.card span {
    font-weight: 600;
    color: white;
    text-align: center;
    display: block;
    padding-top: 10px;
    font-size: 20px;

}

.card .job {
    font-weight: 400;
    color: white;
    display: block;
    text-align: center;
    padding-top: 3px;
    font-size: 12px;
}

.card .img {
    width: 70px;
    height: 80px;
    display: flex;
    background: #6b64f3;
    border-radius: 15px;
    margin: auto;
    padding: 10px;
    justify-content: center;

    margin-top: 25px;
}

.card button {
    padding: 8px 25px;
    display: block;
    margin: auto;
    border-radius: 8px;
    border: none;

    background: #6b64f3;
    color: white;
    font-weight: 600;
}

.card button:hover {
    background: #534bf3;
}
.ride--card, .request--card {
    width: auto;
    background: #002137;
    border-radius: 15px;
    box-shadow: 1px 5px 60px 0px #100a886b;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}


.ride.header, .request.header{
    box-sizing: border-box;
    background-color: #002137;
    border-radius: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin-bottom: 20px;

}

.ride--item, .request--item {
    background: #003A61;
    padding: 10px 15px;
    border-radius: 10px;
    
    font-size: 18px;
    color: white;
    display: flex;

    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    width: auto;
}

.ride--buttons, .request--buttons {
    display: flex;
    gap: 10px;
    margin-left: 60px;
    text-align: center;
    padding: 5px;
}


.ride--info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

.ride--info p {
    font-size: 24px;
}