/* Palette: https://coolors.co/palette/00111c-001523-001a2c-002137-00253e-002945-002e4e-003356-003a61-00406c */


body {
  background: black;
  /* height: auto; */
  width: 100vw;
  /* background-image: url(https://wallpaperaccess.com/full/1811402.jpg); */
  background-repeat: repeat-y;
  font-family: 'Inter', sans-serif;
}
.landing {
  display: flex;
  flex-direction: column;
}
.footer {
  color: white;
  font-size: 16px;
  margin: 30px;
  text-align: center;
}
.header {
  display: flex;
  flex-direction: row;
  box-shadow: -5px 5px 10px #001523;
  background: #001523;
  color: #F4EEE0;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 50px;
  gap: 80px;

  text-decoration: none;
}

.header--links {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 30px;

}
.header--links > a {
  color: #393646;
  color: #F4EEE0;
  padding: 10px;
  font-size: 38px;
  border-radius: 10px;
  text-decoration: none;
  transition: transform 0.2s;
}
.header--links > a:hover {
  transform: scale(1.10)
}
.header--links > a.active {
  text-decoration: underline #F4EEE0;
}

.landing--title {
  color: #00111c;
  font-size: 85px;

}
.landing--container {

  width: 35%;
  display: flex;
  margin: 240px 150px;
  padding: 30px;
  /* padding: 150px 0; */
  flex-direction : column;
  /* justify-content: center; */
  align-items: center;
  border-radius: 12px;

  background-color: #F4EEE0;
  

}

.about--section {
  color: #F4EEE0;
  margin: 100px 10px;
  padding: 20px 60px;
  height: 100%;
  font-size: 24px;
  font-weight: 500;

}
.about--description {

  padding: 20px 60px;
  width: 50%;

  font-size: 1.5rem;
  line-height: 2rem;
  color: #F4EEE0
}
.instructions {
  color: #F4EEE0;
  padding: 20px 60px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 125px;
  margin-bottom: 100px;

}
.instructions h1 {
  align-self: center;
  margin: 50px 0;
  text-decoration: underline;
}
.instructions--components {
  display: flex;
  justify-content: space-around;
}
.instructions--components h2 {
  font-size: 40px;
  width: 30%;
  text-align: center;
}
.instructions--components img {
  width: 450px;
  height: auto;
}
.form--wrapper {

  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 125px auto;
  


}

.user--form {
  padding: 55px 80px;
  background: #F34213;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 500px;
  border-radius: 10px;

}

.user--input--buttons {
  padding: 10px 15px;
  font-size: 18px;
  max-width: 70px;
  border-radius: 6px;
  background-color: white;
  text-align: center;
}

.user--input {
  font-family: 'Inter', sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 6px;
  background-color: white;

}



.form--label {
  font-size: 22px;
  color: #F4EEE0;
  margin-right: 50px;
}
.form--errors {
  font-size: 16px;
  color: #EEF1BD;
  margin-bottom: 10px;
}

.dropdown--content {
  display: none;
  position : absolute;
  background-color:#EEF1BD;
  z-index: 1;
}

.dropdown--content > a {
  color:#393646;
  background: #EEF1BD;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 40px;
  font-size: 25px;
  text-decoration: none;
  display: block;
}

.user--dropdown:hover .dropdown--content {
  display: block;
}

.home-button-style {
  display: flex;
  align-items: center;
  gap: 15px;

}
.home-button-style > a, img {
  color : #F4EEE0;
  text-decoration: none;
  font-weight: 600;
}

.username {
  text-align: center;
  color:#393646;
  color: #F4EEE0;
  
  padding: 10px;
  border-radius: 10px;
  font-size: 38px;
  transition: transform 0.2s;
}
.username:hover {
  transform: scale(1.10);
}

.rides--page, .requests--page {
  width: 90vw;
  height: auto;
  box-sizing: border-box;
  display: block;
  margin: 40px auto;
  border-radius: 12px;
    background: #002945;
    box-shadow: -6px 6px 12px #000a11,
        6px -6px 12px #001827;
  padding: 10px 25px;
}
.rides--page h1, .requests--page h1 {
  color: #F4EEE0;
  font-size: 45px;
  box-sizing: border-box;

}
.rides--heading, .requests--heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.request--info {
  font-size: 24px;
}

